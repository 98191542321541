import {
  put, call, takeLatest,
} from 'redux-saga/effects';
import { actions } from '../../redux/account/forgotPass';
import { apiGetForgotPassword, apiRegister, apiGetProfile } from '../../api/api-user';
import { notification } from '../../components/index';

export function* resetPassRequest(data) {
  try {
    const response = yield call(apiGetForgotPassword, data.payload);
    if (response.status === 200) {
      yield put({
        type: actions.RESET_PASS_SUCCESS,
        payload: {
          success_data: response.data,
        },
      });
      // yield put({
      //   type: actions.RESET_PASS_REQUEST, response
      // });
      return;
    }
    yield put({
      type: actions.RESET_PASS_ERROR,
      payload: {
        message: `${response.status} unknown error`,
      },
    });
  } catch (error) {
    let errorResponse = { status: '', message: '' };
    if (error && error.data) {
      errorResponse = error.data;
      notification('error', `${errorResponse.status} - ${errorResponse.message}`);
    }
    yield put({
      type: actions.RESET_PASS_ERROR,
      payload: {
        error_data: errorResponse,
      },
    });
  }
}

export function* accountRegister(data) {
  try {
    const response = yield call(apiRegister, data.payload);
    if (response.status === 200) {
      yield put({
        type: actions.ACCOUNT_REG_SUCCESS,
        payload: {
          success_reg: response.data,
        },
      });
      return;
    }
    yield put({
      type: actions.ACCOUNT_REG_ERROR,
      payload: {
        message: `${response.status} unknown error`,
      },
    });
  } catch (error) {
    let errorResponse = { message: '' };
    if (error.response && error.response.data) {
      errorResponse = error.response.data;
    }
    yield put({
      type: actions.ACCOUNT_REG_ERROR,
      payload: {
        error_reg: errorResponse,
      },
    });
  }
}

export function* getProfile() {
  try {
    const response = yield call(apiGetProfile);
    if (response.message === 'OK') {
      yield put({
        type: actions.ACCOUNT_PROFILE_SUCCESS,
        payload: {
          success_profile: response.data,
        },
      });
      return;
    }
    yield put({
      type: actions.ACCOUNT_PROFILE_ERROR,
      payload: {
        message: `${response.status} unknown error`,
      },
    });
  } catch (error) {
    let errorResponse = { message: '' };
    if (error.response && error.response.data) {
      errorResponse = error.response.data;
    }
    yield put({
      type: actions.ACCOUNT_PROFILE_ERROR,
      payload: {
        error_profile: errorResponse,
      },
    });
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.RESET_PASS_REQUEST, resetPassRequest);
  yield takeLatest(actions.ACCOUNT_REG_REQUEST, accountRegister);
  yield takeLatest(actions.ACCOUNT_PROFILE_REQUEST, getProfile);
}
