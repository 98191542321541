export const actions = {
  JADWAL_AGENDA_REQUEST: 'JADWAL_AGENDA_REQUEST',
  JADWAL_AGENDA_SUCCESS: 'JADWAL_AGENDA_SUCCESS',
  JADWAL_AGENDA_ERROR: 'JADWAL_AGENDA_ERROR',
  COUNTER_JADWAL_AGENDA_REQUEST: 'COUNTER_JADWAL_AGENDA_REQUEST',
  COUNTER_JADWAL_AGENDA_SUCCESS: 'COUNTER_JADWAL_AGENDA_SUCCESS',
  COUNTER_JADWAL_AGENDA_ERROR: 'COUNTER_JADWAL_AGENDA_ERROR',

  searchJadwalAgenda: (data) => {
    return {
      type: actions.JADWAL_AGENDA_REQUEST,
      data,
    };
  },
  counterJadwalAgenda: (data) => {
    return {
      type: actions.COUNTER_JADWAL_AGENDA_REQUEST,
      data,
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  isLoadingCounter: false,
  counterList: [],
  counterError: null,
};

export default function jadwalAgendaReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.JADWAL_AGENDA_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.JADWAL_AGENDA_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.JADWAL_AGENDA_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };
    case actions.COUNTER_JADWAL_AGENDA_REQUEST:
      return {
        ...state, isLoadingCounter: true, counterError: null,
      };
    case actions.COUNTER_JADWAL_AGENDA_SUCCESS:
      return {
        ...state, ...payload, isLoadingCounter: false, counterError: null,
      };
    case actions.COUNTER_JADWAL_AGENDA_ERROR:
      return {
        ...state, isLoadingCounter: false, counterList: [], ...payload,
      };
    default:
      return state;
  }
}
