export const actions = {
  BANNER_REQUEST: 'BANNER_REQUEST',
  BANNER_SUCCESS: 'BANNER_SUCCESS',
  BANNER_ERROR: 'BANNER_ERROR',

  BANNER_DETAIL_REQUEST: 'BANNER_DETAIL_REQUEST',
  BANNER_DETAIL_SUCCESS: 'BANNER_DETAIL_SUCCESS',
  BANNER_DETAIL_ERROR: 'BANNER_DETAIL_ERROR',

  searchBanner: (data) => {
    return {
      type: actions.BANNER_REQUEST,
      data,
    };
  },
  bannerById: (data) => {
    return {
      type: actions.BANNER_DETAIL_REQUEST,
      data,
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  metaData: {
    count: 0,
    page: 1,
    limit: 10,
  },
  bannerDetailLoading: false,
  bannerDetail: null,
  bannerDetailError: null,
};

export default function bannerReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.BANNER_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.BANNER_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.BANNER_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };

    case actions.BANNER_DETAIL_REQUEST:
      return {
        ...state, bannerDetailLoading: true, bannerDetailError: null, bannerDetail: null,
      };
    case actions.BANNER_DETAIL_SUCCESS:
      return {
        ...state, ...payload, bannerDetailLoading: false, bannerDetailError: null,
      };
    case actions.BANNER_DETAIL_ERROR:
      return {
        ...state, bannerDetailLoading: false, bannerDetail: null, ...payload,
      };
    default:
      return state;
  }
}
