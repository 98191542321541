export const actions = {
  LEGISLATOR_REQUEST: 'LEGISLATOR_REQUEST',
  LEGISLATOR_SUCCESS: 'LEGISLATOR_SUCCESS',
  LEGISLATOR_ERROR: 'LEGISLATOR_ERROR',
  LEGISLATOR_DETAIL: 'LEGISLATOR_DETAIL',
  LEGISLATOR_DETAIL_SUCCESS: 'LEGISLATOR_DETAIL_SUCCESS',
  LEGISLATOR_DETAIL_ERROR: 'LEGISLATOR_DETAIL_ERROR',

  searchLegislator: (data) => {
    return {
      type: actions.LEGISLATOR_REQUEST,
      data,
    };
  },
  legislatorById: (data) => {
    return {
      type: actions.LEGISLATOR_DETAIL,
      id: data,
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  legislatorDetailLoading: false,
  legislatorDetail: null,
  legislatorDetailError: null,
};

export default function legislatorReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LEGISLATOR_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.LEGISLATOR_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.LEGISLATOR_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };
    case actions.LEGISLATOR_DETAIL:
      return {
        ...state, legislatorDetailLoading: true, legislatorDetailError: null, legislatorDetail: null,
      };
    case actions.LEGISLATOR_DETAIL_SUCCESS:
      return {
        ...state, ...payload, legislatorDetailLoading: false, legislatorDetailError: null,
      };
    case actions.LEGISLATOR_DETAIL_ERROR:
      return {
        ...state, legislatorDetailLoading: false, legislatorDetail: null, ...payload,
      };
    default:
      return state;
  }
}
