export const actions = {
  RESET_PASS_REQUEST: 'RESET_PASS_REQUEST',
  RESET_PASS_SUCCESS: 'RESET_PASS_SUCCESS',
  RESET_PASS_ERROR: 'RESET_PASS_ERROR',

  ACCOUNT_REG_REQUEST: 'ACCOUNT_REG_REQUEST',
  ACCOUNT_REG_SUCCESS: 'ACCOUNT_REG_SUCCESS',
  ACCOUNT_REG_ERROR: 'ACCOUNT_REG_ERROR',

  ACCOUNT_PROFILE_REQUEST: 'ACCOUNT_PROFILE_REQUEST',
  ACCOUNT_PROFILE_SUCCESS: 'ACCOUNT_PROFILE_SUCCESS',
  ACCOUNT_PROFILE_ERROR: 'ACCOUNT_PROFILE_ERROR',

  getProfile: () => {
    return {
      type: actions.ACCOUNT_PROFILE_REQUEST,
      payload: null,
    };
  },
  resetPass: (props = { username: '' }) => {
    return {
      type: actions.RESET_PASS_REQUEST,
      payload: props,
    };
  },
  register: (props) => {
    return {
      type: actions.ACCOUNT_REG_REQUEST,
      payload: props,
    };
  },
};

const initState = {
  isLoading: false,
  error_data: null,
  success_data: null,
  error_reg: null,
  success_reg: null,
  error_profile: null,
  success_profile: null,
};

export default function resetReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.RESET_PASS_REQUEST:
      return { ...state, isLoading: true };
    case actions.RESET_PASS_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error_data: { message: '' },
      };
    case actions.RESET_PASS_ERROR:
      return { ...state, isLoading: false, ...payload };

    case actions.ACCOUNT_REG_REQUEST:
      return { ...state, isLoading: true };
    case actions.ACCOUNT_REG_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error_reg: { message: '' },
      };
    case actions.ACCOUNT_REG_ERROR:
      return { ...state, isLoading: false, ...payload };

    case actions.ACCOUNT_PROFILE_REQUEST:
      return { ...state, isLoading: true };
    case actions.ACCOUNT_PROFILE_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error_profile: null,
      };
    case actions.ACCOUNT_PROFILE_ERROR:
      return { ...state, isLoading: false, ...payload };
    default:
      return state;
  }
}
