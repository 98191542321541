import {
  all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import { notification } from '../components';
import { actions } from '../redux/laporan';
import { apiGetLaporan, apiGetLaporanById } from '../api/api-laporan';
import { apiGetCounter } from '../api/api-counter';

export function* searchLaporan() {
  yield takeEvery(actions.LAPORAN_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetLaporan, payload.data);
      if (response.status === 200) {
        yield put({
          type: actions.LAPORAN_SUCCESS,
          payload: {
            list: response.data.data.rows,
            count: response.data.data.count,
          }
        });
        return;
      }
      yield put({
        type: actions.LAPORAN_ERROR,
        payload: {
          message: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.LAPORAN_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export function* counterLaporan() {
  yield takeEvery(actions.COUNTER_LAPORAN_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetCounter, payload.data);
      if (response.status === 200) {
        yield put({
          type: actions.COUNTER_LAPORAN_SUCCESS,
          payload: {
            counterList: response.data.laporan_kegiatan,
          }
        });
        return;
      }
      yield put({
        type: actions.COUNTER_LAPORAN_ERROR,
        payload: {
          message: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.COUNTER_LAPORAN_ERROR,
        payload: {
          errorCounter: errorResponse,
        },
      });
    }
  });
}


export function* detailLaporan() {
  yield takeEvery(actions.LAPORAN_DETAIL, function* (payload) {
    try {
      const response = yield call(apiGetLaporanById, payload.id);
      if (response.status === 200) {
        yield put({
          type: actions.LAPORAN_DETAIL_SUCCESS,
          payload: {
            laporanDetail: response.data.data
          }
        });
        return;
      }
      yield put({
        type: actions.LAPORAN_DETAIL_ERROR,
        payload: {
          laporanDetailError: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.LAPORAN_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(searchLaporan),
    fork(detailLaporan),
    fork(counterLaporan)
  ]);
}
