export const actions = {
  USER_REQUEST: 'USER_REQUEST',
  USER_SUCCESS: 'USER_SUCCESS',
  USER_ERROR: 'USER_ERROR',
  USER_RESET: 'USER_RESET',
  USER_DETAIL_REQUEST: 'USER_DETAIL',
  USER_DETAIL_SUCCESS: 'USER_DETAIL_SUCCESS',
  USER_DETAIL_ERROR: 'USER_DETAIL_ERROR',
  USER_DETAIL_RESET: 'USER_DETAIL_RESET',


  searchUser: (data) => {
    return {
      type: actions.USER_REQUEST,
      data,
    };
  },
  userById: (data) => {
    return {
      type: actions.USER_DETAIL_REQUEST,
      id: data,
    };
  },
  reset: () => {
    return {
      type: actions.USER_RESET,
    };
  },
  resetDetail: () => {
    return {
      type: actions.USER_DETAIL_RESET,
    };
  },
};

const initState = {
  isLoading: false,
  list: [],
  count: 0,
  page: 0,
  error: null,
  limit: 10,
  userDetailLoading: false,
  userDetail: null,
  userDetailError: null,
};

export default function userReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.USER_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.USER_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.USER_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };
    case actions.USER_RESET:
      return {
        ...state,
        isLoading: false,
        list: [],
        count: 0,
        page: 0,
        error: null,
        limit: 10,
        ...payload,
      };

    case actions.USER_DETAIL_REQUEST:
      return {
        ...state, userDetailLoading: true, userDetailError: null, userDetail: null,
      };
    case actions.USER_DETAIL_SUCCESS:
      return {
        ...state, ...payload, userDetailLoading: false, userDetailError: null,
      };
    case actions.USER_DETAIL_ERROR:
      return {
        ...state, userDetailLoading: false, userDetail: null, ...payload,
      };
    default:
      return state;
  }
}
