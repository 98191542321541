import {
  all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import { notification } from '../components';
import { actions } from '../redux/ratingInternal';
import { apiGetRatingsInternal, apiGetDetailRatingInternal } from '../api/api-rating-internal';

export function* searchRatingInternal() {
  yield takeEvery(actions.RATING_INTERNAL_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetRatingsInternal, payload.data);
      if (response.status === 200) {
        yield put({
          type: actions.RATING_INTERNAL_SUCCESS,
          payload: {
            list: response.data.data.rows,
            count: response.data.data.count,
          }
        });
        return;
      }
      yield put({
        type: actions.RATING_INTERNAL_ERROR,
        payload: {
          message: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.RATING_INTERNAL_ERROR,
        payload: {
          error: errorResponse,
        },
      });
    }
  });
}

export function* detailRatingInternal() {
  yield takeEvery(actions.RATING_INTERNAL_DETAIL, function* (payload) {
    try {
      const response = yield call(apiGetDetailRatingInternal, payload);
      if (response.status === 200) {
        yield put({
          type: actions.RATING_INTERNAL_DETAIL_SUCCESS,
          payload: {
            ratingInternalDetail: response.data.data
          }
        });
        return;
      }
      yield put({
        type: actions.RATING_INTERNAL_DETAIL_ERROR,
        payload: {
          rratingInternalDetailError: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.RATING_INTERNAL_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(searchRatingInternal),
    fork(detailRatingInternal)
  ]);
}
