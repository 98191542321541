import {
  all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import { notification } from '../components';
import { actions } from '../redux/roletype';
import { apiGetRoleTypes } from '../api/api-master-data';

export function* searchRoleType() {
  yield takeEvery(actions.ROLETYPE_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetRoleTypes, payload.data);
      yield put({
        type: actions.ROLETYPE_SUCCESS,
        payload: {
          list: response.rows,
          metaData: {
            count: response.count,
            page: payload.data.page,
            limit: payload.data.limit,
          }
        }
      });
      return;
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.ROLETYPE_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(searchRoleType),
  ]);
}
