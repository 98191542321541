const options = [
  { // 1
    key: 'Legislator',
    label: 'Create Legislator',
    leftIcon: 'fund',
    children: [
      {
        key: 'createLegislator',
        label: 'Create',
      },
    ],
  },
];
export default options;
