export const actions = {
  PROVINCE_REQUEST: 'PROVINCE_REQUEST',
  PROVINCE_SUCCESS: 'PROVINCE_SUCCESS',
  PROVINCE_ERROR: 'PROVINCE_ERROR',

  PROVINCE_DETAIL_REQUEST: 'PROVINCE_DETAIL_REQUEST',
  PROVINCE_DETAIL_SUCCESS: 'PROVINCE_DETAIL_SUCCESS',
  PROVINCE_DETAIL_ERROR: 'PROVINCE_DETAIL_ERROR',

  searchProvince: (data) => {
    return {
      type: actions.PROVINCE_REQUEST,
      data,
    };
  },
  provinceById: (data) => {
    return {
      type: actions.PROVINCE_DETAIL_REQUEST,
      data,
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  metaData: {
    count: 0,
    page: 1,
    limit: 10,
  },
  provinceDetailLoading: false,
  provinceDetail: null,
  provinceDetailError: null,
};

export default function provinceReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.PROVINCE_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.PROVINCE_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.PROVINCE_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };

    case actions.PROVINCE_DETAIL_REQUEST:
      return {
        ...state, provinceDetailLoading: true, provinceDetailError: null, provinceDetail: null,
      };
    case actions.PROVINCE_DETAIL_SUCCESS:
      return {
        ...state, ...payload, provinceDetailLoading: false, provinceDetailError: null,
      };
    case actions.PROVINCE_DETAIL_ERROR:
      return {
        ...state, provinceDetailLoading: false, provinceDetail: null, ...payload,
      };
    default:
      return state;
  }
}
