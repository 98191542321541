export const actions = {
  RATING_INTERNAL_REQUEST: 'RATING_INTERNAL_REQUEST',
  RATING_INTERNAL_SUCCESS: 'RATING_INTERNAL_SUCCESS',
  RATING_INTERNAL_ERROR: 'RATING_INTERNAL_ERROR',
  RATING_INTERNAL_DETAIL: 'RATING_INTERNAL_DETAIL',
  RATING_INTERNAL_DETAIL_SUCCESS: 'RATING_INTERNAL_DETAIL_SUCCESS',
  RATING_INTERNAL_DETAIL_ERROR: 'RATING_INTERNAL_DETAIL_ERROR',

  searchRatingInternal: (data) => {
    return {
      type: actions.RATING_INTERNAL_REQUEST,
      data,
    };
  },
  ratingInternalById: (data) => {
    return {
      type: actions.RATING_INTERNAL_DETAIL,
      id: data,
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  ratingInternalDetailLoading: false,
  ratingInternalDetail: null,
  ratingInternalDetailError: null,
};

export default function ratingInternalReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.RATING_INTERNAL_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.RATING_INTERNAL_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.RATING_INTERNAL_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };
    case actions.RATING_INTERNAL_DETAIL:
      return {
        ...state, ratingInternalDetailLoading: true, ratingInternalDetailError: null, ratingInternalDetail: null,
      };
    case actions.RATING_INTERNAL_DETAIL_SUCCESS:
      return {
        ...state, ...payload, ratingInternalDetailLoading: false, ratingInternalDetailError: null,
      };
    case actions.RATING_INTERNAL_DETAIL_ERROR:
      return {
        ...state, ratingInternalDetailLoading: false, ratingInternalDetail: null, ...payload,
      };
    default:
      return state;
  }
}
