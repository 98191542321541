export const actions = {
  LAPORAN_REQUEST: 'LAPORAN_REQUEST',
  LAPORAN_SUCCESS: 'LAPORAN_SUCCESS',
  LAPORAN_ERROR: 'LAPORAN_ERROR',
  COUNTER_LAPORAN_REQUEST: 'COUNTER_LAPORAN_REQUEST',
  COUNTER_LAPORAN_SUCCESS: 'COUNTER_LAPORAN_SUCCESS',
  COUNTER_LAPORAN_ERROR: 'COUNTTER_LAPORAN_ERROR',
  LAPORAN_DETAIL: 'LAPORAN_DETAIL',
  LAPORAN_DETAIL_SUCCESS: 'LAPORAN_DETAIL_SUCCESS',
  LAPORAN_DETAIL_ERROR: 'LAPORAN_DETAIL_ERROR',

  searchLaporan: (data) => {
    return {
      type: actions.LAPORAN_REQUEST,
      data,
    };
  },
  searchCounter: (data) => {
    return {
      type: actions.COUNTER_LAPORAN_REQUEST,
      data,
    };
  },
  laporanById: (data) => {
    return {
      type: actions.LAPORAN_DETAIL,
      id: data,
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  isLoadingCounter: false,
  counterList: [],
  errorCounter: null,
  laporanDetailLoading: false,
  laporanDetail: null,
  laporanDetailError: null,
};

export default function laporanReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LAPORAN_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.LAPORAN_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.LAPORAN_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };
    case actions.COUNTER_LAPORAN_REQUEST:
      return {
        ...state, isLoadingCounter: true, errorCounter: null,
      };
    case actions.COUNTER_LAPORAN_SUCCESS:
      return {
        ...state, ...payload, isLoadingCounter: false, errorCounter: null,
      };
    case actions.COUNTER_LAPORAN_ERROR:
      return {
        ...state, isLoadingCounter: false, listCounter: [], ...payload,
      };
    case actions.LAPORAN_DETAIL:
      return {
        ...state, laporanDetailLoading: true, laporanDetailError: null, laporanDetail: null,
      };
    case actions.LAPORAN_DETAIL_SUCCESS:
      return {
        ...state, ...payload, laporanDetailLoading: false, laporanDetailError: null,
      };
    case actions.LAPORAN_DETAIL_ERROR:
      return {
        ...state, laporanDetailLoading: false, laporanDetail: null, ...payload,
      };
    default:
      return state;
  }
}
