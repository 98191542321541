export const actions = {
  STATISTIK_ADUAN_REQUEST: 'STATISTIK_ADUAN_REQUEST',
  STATISTIK_ADUAN_SUCCESS: 'STATISTIK_ADUAN_SUCCESS',
  STATISTIK_ADUAN_ERROR: 'STATISTIK_ADUAN_ERROR',

  getStatistikAduan: () => {
    return {
      type: actions.STATISTIK_ADUAN_REQUEST
    };
  },
};

const initState = {
  is_loading: false,
  aduan_count: 0,
  aduan_opened: 0,
  aduan_completed: 0,
  aduan_legislator: [],
  aduan_category: [],
  error_message: null,
};

export default function StatistikUsers(state = initState, { type, payload }) {
  switch (type) {
    case actions.STATISTIK_ADUAN_REQUEST:
      return {
        ...state, is_loading: true, error_message: null,
      };
    case actions.STATISTIK_ADUAN_SUCCESS:
      return {
        ...state, ...payload, is_loading: false, error_message: null,
      };
    case actions.STATISTIK_ADUAN_ERROR:
      return {
        ...state, is_loading: false, active_users: 0, usia: null, demografi: null, ...payload,
      };
    default:
      return state;
  }
}
