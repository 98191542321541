import {
  all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import { notification } from '../components';
import { actions } from '../redux/district';
import { apiGetListDistrict, apiGetDistrictById } from '../api/api-master-data';

export function* searchDistrict() {
  yield takeEvery(actions.DISTRICT_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetListDistrict, payload.data);
      if (response.status === 200) {
        yield put({
          type: actions.DISTRICT_SUCCESS,
          payload: {
            list: response.data.data.rows,
            metaData: {
              count: response.data.data.count,
              page: payload.data.page,
              limit: payload.data.limit,
            }
          }
        });
        return;
      }
      yield put({
        type: actions.DISTRICT_ERROR,
        payload: {
          message: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.DISTRICT_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export function* detailDistrict() {
  yield takeEvery(actions.DISTRICT_DETAIL_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetDistrictById, payload);
      if (response.status === 200) {
        yield put({
          type: actions.DISTRICT_DETAIL_SUCCESS,
          payload: {
            districtDetail: response.data.data
          }
        });
        return;
      }
      yield put({
        type: actions.DISTRICT_DETAIL_ERROR,
        payload: {
          districtDetailError: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.DISTRICT_DETAIL_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(searchDistrict),
    fork(detailDistrict)
  ]);
}
