/* eslint-disable no-async-promise-executor */
import axios from '../plugins/axios';

export function apiGetPokir(props) {
  return new Promise(async (resolve, reject) => {
    const url = '/pokir';
    try {
      const response = await axios({
        method: 'GET',
        url,
        params: props
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetPokirById(id) {
  return new Promise(async (resolve, reject) => {
    const url = `/pokir/${id}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetTugas(props) {
  return new Promise(async (resolve, reject) => {
    const url = '/tugas-legislator';
    try {
      const response = await axios({
        method: 'GET',
        url,
        params: props
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}


export function apiGetTugasById(id) {
  return new Promise(async (resolve, reject) => {
    const url = `/tugas-legislator/${id}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}