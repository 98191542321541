/* eslint-disable import/prefer-default-export */
/* eslint-disable no-async-promise-executor */
import axios from '../plugins/axios';
import axiosEnc from '../plugins/axiosEnc';

export function apiGetRatingsInternal(props) {
  return new Promise(async (resolve, reject) => {
    const url = '/ratings';
    try {
      const response = await axios({
        method: 'GET',
        url,
        params: props
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetDetailRatingInternal(props) {
  return new Promise(async (resolve, reject) => {
    const url = `/ratings_internal/${props.id}`;
    try {
      const response = await axios({
        method: 'GET',
        url,
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiDeleteRatingInternal(props) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'DELETE',
      url: `/ratings_internal/${props.legislator_id}/rating/${props.id}`,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function apiPostRatingInternal(data) {
  return new Promise((resolve, reject) => {
    axiosEnc({
      method: 'POST',
      url: '/ratings_internal',
      data,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}
