const options = [
  { // 1
    key: 'statistik',
    label: 'Statistik',
    leftIcon: 'pie-chart',
    children: [
      {
        key: 'statistik-users',
        label: 'Users',
      },
      {
        key: 'statistik-Legislator',
        label: 'Legislator',
      },
      {
        key: 'statistik-aduan',
        label: 'Aduan',
      },
    ],
  },
  { // 1
    key: 'user',
    label: 'User Management',
    leftIcon: 'user',
    children: [
      {
        key: 'user',
        label: 'List User',
      },
    ],
  },
  { // 2
    key: 'penugasan',
    label: 'Penugasan Legislator',
    leftIcon: 'project'
  },
  { // 2
    key: 'streaming',
    label: 'Streaming Legislator',
    leftIcon: 'project'
  },
  { // 3
    key: 'rapbd',
    label: 'RAPBD & Raperda Management',
    leftIcon: 'profile'
  },
  { // 4
    key: 'agenda',
    label: 'Schedule Management',
    leftIcon: 'calendar'
  },
  { // 5
    key: 'masterData',
    label: 'Master Data Management',
    leftIcon: 'database',
    children: [
      {
        key: 'province',
        label: 'List Province',
      },
      {
        key: 'city',
        label: 'List City',
      },
      {
        key: 'district',
        label: 'List District',
      },
      {
        key: 'role',
        label: 'List Role',
      },
      {
        key: 'roleType',
        label: 'List Role Type',
      },
      {
        key: 'village',
        label: 'List Village',
      },
      {
        key: 'banner',
        label: 'List Banner',
      },
    ],
  },
  {
    key: 'legislator',
    label: 'Legislator Management',
    leftIcon: 'solution',
    children: [
      {
        key: 'legislator',
        label: 'List Legislator',
      },
      {
        key: 'ratingLegislator',
        label: 'List Rating Legislator',
      },
    ],
  },
  {
    key: 'aduanMasyarkaat',
    label: 'Aduan Masyarakat',
    leftIcon: 'solution',
    children: [
      {
        key: 'aduanMasyarakat',
        label: 'List Aduan Masyarakat',
      },
    ],
  },
  {
    key: 'laporan',
    label: 'Laporan Management',
    leftIcon: 'solution',
    children: [
      {
        key: 'laporan',
        label: 'List Laporan Management',
      },
    ],
  },
];
export default options;
