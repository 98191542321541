import React from 'react';
import { Link } from 'react-router-dom';
import { siteConfig } from '../../settings';
import logo from '../../image/bg/logo_2.png';

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/dashboard">
              <i className={siteConfig.siteIcon} />
            </Link>
          </h3>
        </div>
      ) : (
        <Link to="/dashboard" style={{ width: 'inherit', height: 'inherit' }}>
          <img style={{ width: 'inherit', height: 'inherit', padding: '2px' }} src={logo} alt="logo" />
        </Link>
      )}
    </div>
  );
};
