import {
  all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import { notification } from '../components';
import { actions } from '../redux/banner';
import { apiGetBanner, apiGetBannerById } from '../api/api-master-data';

export function* searchBanner() {
  yield takeEvery(actions.BANNER_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetBanner, payload.data);
      if (response.status === 200) {
        yield put({
          type: actions.BANNER_SUCCESS,
          payload: {
            list: response.data.data.rows,
            metaData: {
              count: response.data.data.count,
              page: payload.data.page,
              limit: payload.data.limit,
            }
          }
        });
        return;
      }
      yield put({
        type: actions.BANNER_ERROR,
        payload: {
          message: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.BANNER_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export function* detailBanner() {
  yield takeEvery(actions.BANNER_DETAIL_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetBannerById, payload);
      if (response.status === 200) {
        yield put({
          type: actions.BANNER_DETAIL_SUCCESS,
          payload: {
            bannerDetail: response.data.data
          }
        });
        return;
      }
      yield put({
        type: actions.BANNER_DETAIL_ERROR,
        payload: {
          bannerDetailError: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.BANNER_DETAIL_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(searchBanner),
    fork(detailBanner)
  ]);
}
