import {
  all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import { notification } from '../components';
import { actions as ActionUsers } from '../redux/statistikUsers';
import { actions as ActionAduan } from '../redux/statistikAduan';
import {
  getDemografi,
  getStatistikLegislator,
  getTableLegislator,
  getDemografiAduan,
} from '../api/api-util';

export function* apiDemografiUser() {
  yield takeEvery(ActionUsers.STATISTIK_USER_REQUEST, function* () {
    try {
      const response = yield call(getDemografi);
      if (response) {
        yield put({
          type: ActionUsers.STATISTIK_USER_SUCCESS,
          payload: {
            active_users: response.active_users,
            usia: response.usia,
            demografi: response.demografi,
          }
        });
        return;
      }
      yield put({
        type: ActionUsers.STATISTIK_USER_ERROR,
        payload: {
          message: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      if (error.data) {
        notification('error', 'Something went wrong', error.data.error.message);
      } else {
        notification('error', error.message, '');
      }
      yield put({
        type: ActionUsers.STATISTIK_USER_ERROR,
        payload: {
          error_message: error.message,
        },
      });
    }
  });
}

export function* apiStatistikLegislator() {
  yield takeEvery(ActionUsers.STATISTIK_LEGISLATOR_REQUEST, function* () {
    try {
      const response = yield call(getStatistikLegislator);
      if (response) {
        yield put({
          type: ActionUsers.STATISTIK_LEGISLATOR_SUCCESS,
          payload: {
            legislator_pokir: response.statistik.pokir,
            legislator_laporan: response.statistik.laporan,
            legislator_rapbd: response.statistik.rapbd,
          }
        });
        return;
      }
      yield put({
        type: ActionUsers.STATISTIK_LEGISLATOR_ERROR,
        payload: {
          legislator_error: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      if (error.data) {
        notification('error', 'Something went wrong', error.data.error.message);
      } else {
        notification('error', error.message, '');
      }
      yield put({
        type: ActionUsers.STATISTIK_LEGISLATOR_ERROR,
        payload: {
          legislator_error: error.message,
        },
      });
    }
  });
}

export function* apiTableLegislator() {
  yield takeEvery(ActionUsers.TABLE_LEGISLATOR_REQUEST, function* (payload) {
    try {
      const response = yield call(getTableLegislator, payload.data);
      if (response) {
        yield put({
          type: ActionUsers.TABLE_LEGISLATOR_SUCCESS,
          payload: {
            table_legislator: response,
          }
        });
        return;
      }
      yield put({
        type: ActionUsers.TABLE_LEGISLATOR_ERROR,
        payload: {
          table_legislator_error: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      if (error.data) {
        notification('error', 'Something went wrong', error.data.error.message);
      } else {
        notification('error', error.message, '');
      }
      yield put({
        type: ActionUsers.TABLE_LEGISLATOR_ERROR,
        payload: {
          table_legislator_error: error.message,
        },
      });
    }
  });
}

export function* apiStatistikAduan() {
  yield takeEvery(ActionAduan.STATISTIK_ADUAN_REQUEST, function* () {
    try {
      const response = yield call(getDemografiAduan);
      if (response) {
        yield put({
          type: ActionAduan.STATISTIK_ADUAN_SUCCESS,
          payload: {
            aduan_count: response.count,
            aduan_opened: response.opened,
            aduan_completed: response.completed,
            aduan_category: response.aduan_category,
            aduan_legislator: response.aduan_legislator.map((i, j) => ({ key: j, ...i }))
          }
        });
        return;
      }
      yield put({
        type: ActionAduan.STATISTIK_ADUAN_ERROR,
        payload: {
          error_message: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      if (error.data) {
        notification('error', 'Something went wrong', error.data.error.message);
      } else {
        notification('error', error.message, '');
      }
      yield put({
        type: ActionAduan.STATISTIK_ADUAN_ERROR,
        payload: {
          error_message: error.message,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(apiDemografiUser),
    fork(apiStatistikLegislator),
    fork(apiTableLegislator),
    fork(apiStatistikAduan),
  ]);
}
