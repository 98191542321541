/* eslint-disable no-async-promise-executor */
import axios from '../plugins/axios';

export function apiGetRapbd(props) {
  return new Promise(async (resolve, reject) => {
    const url = '/rapbd';
    try {
      const response = await axios({
        method: 'GET',
        url,
        params: props
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetRapbdById(id) {
  return new Promise(async (resolve, reject) => {
    const url = `/rapbd/${id}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
