export const actions = {
  GET_TUGAS_REQUEST: 'GET_TUGAS_REQUEST',
  GET_TUGAS_SUCCESS: 'GET_TUGAS_SUCCESS',
  GET_TUGAS_ERROR: 'GET_TUGAS_ERROR',
  GET_TUGAS_DETAIL_REQUEST: 'GET_TUGAS_DETAIL_REQUEST',
  GET_TUGAS_DETAIL_SUCCESS: 'GET_TUGAS_DETAIL_SUCCESS',
  GET_TUGAS_DETAIL_ERROR: 'GET_TUGAS_DETAIL_ERROR',

  getTugas: (data) => {
    return {
      type: actions.GET_TUGAS_REQUEST,
      data
    };
  },
  getDetailById: (id) => {
    return {
      type: actions.GET_TUGAS_DETAIL_REQUEST,
      id
    };
  }
};

const initState = {
  is_loading: false,
  data: [],
  error_message: null,
  detail_loading: false,
  detail_data: null,
  detail_error: null,
};

export default function StatistikUsers(state = initState, { type, payload }) {
  switch (type) {
    case actions.GET_TUGAS_REQUEST:
      return {
        ...state, is_loading: true, error_message: null,
      };
    case actions.GET_TUGAS_SUCCESS:
      return {
        ...state, ...payload, is_loading: false, error_message: null,
      };
    case actions.GET_TUGAS_ERROR:
      return {
        ...state, is_loading: false
      };
    case actions.GET_TUGAS_DETAIL_REQUEST:
      return {
        ...state, detail_loading: true, error_message: null,
      };
    case actions.GET_TUGAS_DETAIL_SUCCESS:
      return {
        ...state, ...payload, detail_loading: false, error_message: null,
      };
    case actions.GET_TUGAS_DETAIL_ERROR:
      return {
        ...state, detail_loading: false
      };
    default:
      return state;
  }
}
