/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import jwtDecode from 'jwt-decode';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import userpic from '../../image/avatars/0.png';
import { actions as authAction } from '../../redux/auth';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { URL_PROFILE } from '../../page-url';

const { logout } = authAction;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
      user: jwtDecode(localStorage.getItem('token')),
    };
  }

  hide() {
    this.setState({ visible: false });
  }

  handleVisibleChange() {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  }

  render() {
    const { props, state } = this;
    const { user } = state;
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <Link to={URL_PROFILE()} className="isoDropdownLink">
          <IntlMessages id="page.profile" />
        </Link>
        <Link to="#" className="isoDropdownLink" onClick={props.logout}>
          <IntlMessages id="topbar.logout" />
        </Link>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter
        placement="bottomLeft"
      >
        <div className="profile">
          <Row gutter={8}>
            <Col span={8}>
              <div className="isoImgWrapper">
                <img alt="user" src={userpic} />
                <span className="userActivity online" />
              </div>
            </Col>
            <Col span={16}>
              <div className="name">{user.name}</div>
              <div className="nik">NIK: <span className="detail">{user.nik}</span></div>
            </Col>
          </Row>
        </div>
      </Popover>
    );
  }
}
export default connect(
  (state) => ({
    ...state.auth,
  }),
  { logout },
)(TopbarUser);
