import {
  all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import { notification } from '../components';
import { actions } from '../redux/aduan';
import { apiGetUserTickets, apiGetTicketDetail } from '../api/api-aduan';

export function* searchAduan() {
  yield takeEvery(actions.ADUAN_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetUserTickets, payload.data);
      if (response.status === 200) {
        yield put({
          type: actions.ADUAN_SUCCESS,
          payload: {
            list: response.data.data.rows,
            count: response.data.data.count,
          }
        });
        return;
      }
      yield put({
        type: actions.ADUAN_ERROR,
        payload: {
          message: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.ADUAN_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export function* detailAduan() {
  yield takeEvery(actions.ADUAN_DETAIL, function* (payload) {
    try {
      const response = yield call(apiGetTicketDetail, payload.id);
      if (response.status === 200) {
        yield put({
          type: actions.ADUAN_DETAIL_SUCCESS,
          payload: {
            aduanDetail: response.data.data
          }
        });
        return;
      }
      yield put({
        type: actions.ADUAN_DETAIL_ERROR,
        payload: {
          aduanDetailError: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.ADUAN_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(searchAduan),
    fork(detailAduan)
  ]);
}
