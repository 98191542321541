export const actions = {
  ADUAN_REQUEST: 'ADUAN_REQUEST',
  ADUAN_SUCCESS: 'ADUAN_SUCCESS',
  ADUAN_ERROR: 'ADUAN_ERROR',
  ADUAN_DETAIL: 'ADUAN_DETAIL',
  ADUAN_DETAIL_SUCCESS: 'ADUAN_DETAIL_SUCCESS',
  ADUAN_DETAIL_ERROR: 'ADUAN_DETAIL_ERROR',

  searchAduan: (data) => {
    return {
      type: actions.ADUAN_REQUEST,
      data,
    };
  },
  aduanById: (data) => {
    return {
      type: actions.ADUAN_DETAIL,
      id: data,
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  aduanDetailLoading: false,
  aduanDetail: null,
  aduanDetailError: null,
};

export default function aduanReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.ADUAN_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.ADUAN_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.ADUAN_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };
    case actions.ADUAN_DETAIL:
      return {
        ...state, aduanDetailLoading: true, aduanDetailError: null, aduanDetail: null,
      };
    case actions.ADUAN_DETAIL_SUCCESS:
      return {
        ...state, ...payload, aduanDetailLoading: false, aduanDetailError: null,
      };
    case actions.ADUAN_DETAIL_ERROR:
      return {
        ...state, aduanDetailLoading: false, aduanDetail: null, ...payload,
      };
    default:
      return state;
  }
}
