export const actions = {
  POKIR_REQUEST: 'POKIR_REQUEST',
  POKIR_SUCCESS: 'POKIR_SUCCESS',
  POKIR_ERROR: 'POKIR_ERROR',
  POKIR_DETAIL: 'POKIR_DETAIL',
  POKIR_DETAIL_SUCCESS: 'POKIR_DETAIL_SUCCESS',
  POKIR_DETAIL_ERROR: 'POKIR_DETAIL_ERROR',

  searchPokir: (data) => {
    return {
      type: actions.POKIR_REQUEST,
      data,
    };
  },
  pokirById: (data) => {
    return {
      type: actions.POKIR_DETAIL,
      id: data,
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  pokirDetailLoading: false,
  pokirDetail: null,
  pokirDetailError: null,
};

export default function pokirReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.POKIR_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.POKIR_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.POKIR_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };
    case actions.POKIR_DETAIL:
      return {
        ...state, pokirDetailLoading: true, pokirDetailError: null, pokirDetail: null,
      };
    case actions.POKIR_DETAIL_SUCCESS:
      return {
        ...state, ...payload, pokirDetailLoading: false, pokirDetailError: null,
      };
    case actions.POKIR_DETAIL_ERROR:
      return {
        ...state, pokirDetailLoading: false, pokirDetail: null, ...payload,
      };
    default:
      return state;
  }
}
