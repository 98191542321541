/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import clone from 'clone';
import { Link } from 'react-router-dom';
import { Layout, Icon } from 'antd';
import options from './options';
import Scrollbars from '../../../components/utility/customScrollBar';
import Menu from '../../../components/uielements/menu';
import SidebarWrapper from './sidebar.style';
import { actions as appActions } from '../../../redux/app';
import Logo from '../../../components/utility/logo';
import themes from '../../../settings/themes';
import { themeConfig } from '../../../settings';
import { URL_ROOT } from '../../../page-url';

const { SubMenu } = Menu;
const { Sider } = Layout;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;
const stripTrailingSlash = (str) => {
  if (str.substr(-1) === URL_ROOT()) {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps && nextProps.userPermission && nextProps.userPermission.app && nextProps.userPermission.app.menus !== prevState.menu) {
      const perm = nextProps.userPermission;
      return { menu: perm.app.menus };
    }
    return null;
  }

  onOpenChange(newOpenKeys) {
    // eslint-disable-next-line no-shadow
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      (key) => !(app.openKeys.indexOf(key) > -1),
    );
    const latestCloseKey = app.openKeys.find(
      (key) => !(newOpenKeys.indexOf(key) > -1),
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }

  getAncestorKeys = (key) => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };

  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { props } = this;
    const {
      key, label, leftIcon, children,
    } = singleOption;
    const url = stripTrailingSlash(props.url);
    if (children) {
      return (
        <SubMenu
          key={key}
          title={(
            <span className="isoMenuHolder" style={submenuColor}>
              <Icon type={leftIcon} />
              <span className="nav-text">
                <div>{label}</div>
              </span>
            </span>
          )}
        >
          {children.map((child) => {
            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`;
            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link style={submenuColor} to={linkTo}>
                  <div>{child.label}</div>
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={leftIcon} />
            <span className="nav-text">
              <div>{label}</div>
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };

  getMenuItemRemote = ({ singleOption, submenuStyle, submenuColor }) => {
    const { props } = this;
    const { menu } = this.state;
    const {
      key, label, leftIcon, children,
    } = singleOption;
    const url = stripTrailingSlash(props.url);
    if (children) {
      let total = 0;
      children.forEach((child) => {
        const find = menu.find((el) => el.name === child.key);
        if (find !== undefined) {
          total += 1;
        }
      });
      if (total !== 0) {
        return (
          <SubMenu
            key={key}
            title={(
              <span className="isoMenuHolder" style={submenuColor}>
                <Icon type={leftIcon} />
                <span className="nav-text">
                  <div>{label}</div>
                </span>
              </span>
            )}
          >
            {children.map((child) => {
              const linkTo = child.withoutDashboard ? `/${child.key}` : `${url}/${child.key}`;
              const find = menu.find((el) => el.name === child.key);
              if (find !== undefined) {
                return (
                  <Menu.Item style={submenuStyle} key={child.key}>
                    <Link style={submenuColor} to={linkTo}>
                      <div>{child.label}</div>
                    </Link>
                  </Menu.Item>
                );
              }
              return null;
            })}
          </SubMenu>
        );
      }
    }
    const find = menu.find((el) => el.name === key);
    if (find !== undefined) {
      return (
        <Menu.Item key={key}>
          <Link to={`${url}/${key}`}>
            <span className="isoMenuHolder" style={submenuColor}>
              <i className={leftIcon} />
              <span className="nav-text">
                <div>{label}</div>
              </span>
            </span>
          </Link>
        </Menu.Item>
      );
    }
    return null;
  };

  handleClick(e) {
    const { props } = this;
    props.changeCurrent([e.key]);
    if (props.app.view === 'MobileViewzzz') {
      setTimeout(() => {
        props.toggleCollapsed();
        props.toggleOpenDrawer();
      }, 100);
    }
  }

  render() {
    // eslint-disable-next-line no-shadow
    const { app, toggleOpenDrawer, height } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? 'vertical' : 'inline';
    // const onMouseEnter = () => {
    //   if (openDrawer === false) {
    //     toggleOpenDrawer();
    //   }
    // };
    // const onMouseLeave = () => {
    //   if (openDrawer === true) {
    //     toggleOpenDrawer();
    //   }
    // };
    const customizedTheme = themes[themeConfig.theme];
    const styling = {
      backgroundColor: '#005add',
    };
    const submenuStyle = {
      // backgroundColor: 'rgba(0,0,0,0.3)',
      color: customizedTheme.textColor,
    };
    const submenuColor = {
      color: customizedTheme.textColor,
    };
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={240}
          className="isomorphicSidebar"
          // onMouseEnter={onMouseEnter}
          // onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: height - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {options.map((singleOption) => this.getMenuItem({ submenuStyle, submenuColor, singleOption }))}
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    app: state.app,
    height: state.app.height,
    userPermission: state.auth.user,
  }),
  {
    toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed,
  },
)(Sidebar);
