import {
  all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import { notification } from '../components';
import { actions } from '../redux/city';
import { apiGetListCity, apiGetCityById } from '../api/api-master-data';

export function* searchCity() {
  yield takeEvery(actions.CITY_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetListCity, payload.data);
      if (response.status === 200) {
        yield put({
          type: actions.CITY_SUCCESS,
          payload: {
            list: response.data.data.rows,
            metaData: {
              count: response.data.data.count,
              page: payload.data.page,
              limit: payload.data.limit,
            }
          }
        });
        return;
      }
      yield put({
        type: actions.CITY_ERROR,
        payload: {
          message: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.CITY_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export function* detailCity() {
  yield takeEvery(actions.CITY_DETAIL_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetCityById, payload);
      if (response.status === 200) {
        yield put({
          type: actions.CITY_DETAIL_SUCCESS,
          payload: {
            cityDetail: response.data.data
          }
        });
        return;
      }
      yield put({
        type: actions.CITY_DETAIL_ERROR,
        payload: {
          cityDetailError: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.CITY_DETAIL_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(searchCity),
    fork(detailCity)
  ]);
}
