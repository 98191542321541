export const actions = {
  VILLAGE_REQUEST: 'VILLAGE_REQUEST',
  VILLAGE_SUCCESS: 'VILLAGE_SUCCESS',
  VILLAGE_ERROR: 'VILLAGE_ERROR',

  VILLAGE_DETAIL_REQUEST: 'VILLAGE_DETAIL_REQUEST',
  VILLAGE_DETAIL_SUCCESS: 'VILLAGE_DETAIL_SUCCESS',
  VILLAGE_DETAIL_ERROR: 'VILLAGE_DETAIL_ERROR',

  searchVillage: (data) => {
    return {
      type: actions.VILLAGE_REQUEST,
      data,
    };
  },
  villageById: (data) => {
    return {
      type: actions.VILLAGE_DETAIL_REQUEST,
      data,
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  metaData: {
    count: 0,
    page: 1,
    limit: 10,
  },
  villageDetailLoading: false,
  villageDetail: null,
  villageDetailError: null,
};

export default function villageReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.VILLAGE_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.VILLAGE_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.VILLAGE_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };

    case actions.VILLAGE_DETAIL_REQUEST:
      return {
        ...state, villageDetailLoading: true, villageDetailError: null, villageDetail: null,
      };
    case actions.VILLAGE_DETAIL_SUCCESS:
      return {
        ...state, ...payload, villageDetailLoading: false, villageDetailError: null,
      };
    case actions.VILLAGE_DETAIL_ERROR:
      return {
        ...state, villageDetailLoading: false, villageDetail: null, ...payload,
      };
    default:
      return state;
  }
}
