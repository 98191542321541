import React from 'react';
import ReactDOM from 'react-dom';
import DashApp from './dashApp';
import * as serviceWorker from './serviceWorker';
import Config from './Config';
import 'antd/dist/antd.css';
import '../node_modules/react-modal-video/scss/modal-video.scss';


ReactDOM.render(<DashApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register(Config);
