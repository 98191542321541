export const actions = {
  DISTRICT_REQUEST: 'DISTRICT_REQUEST',
  DISTRICT_SUCCESS: 'DISTRICT_SUCCESS',
  DISTRICT_ERROR: 'DISTRICT_ERROR',

  DISTRICT_DETAIL_REQUEST: 'DISTRICT_DETAIL_REQUEST',
  DISTRICT_DETAIL_SUCCESS: 'DISTRICT_DETAIL_SUCCESS',
  DISTRICT_DETAIL_ERROR: 'DISTRICT_DETAIL_ERROR',

  searchDistrict: (data) => {
    return {
      type: actions.DISTRICT_REQUEST,
      data,
    };
  },
  districtById: (data) => {
    return {
      type: actions.DISTRICT_DETAIL_REQUEST,
      data,
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  metaData: {
    count: 0,
    page: 1,
    limit: 10,
  },
  districtDetailLoading: false,
  districtDetail: null,
  districtDetailError: null,
};

export default function districtReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.DISTRICT_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.DISTRICT_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.DISTRICT_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };

    case actions.DISTRICT_DETAIL_REQUEST:
      return {
        ...state, districtDetailLoading: true, districtDetailError: null, districtDetail: null,
      };
    case actions.DISTRICT_DETAIL_SUCCESS:
      return {
        ...state, ...payload, districtDetailLoading: false, districtDetailError: null,
      };
    case actions.DISTRICT_DETAIL_ERROR:
      return {
        ...state, districtDetailLoading: false, districtDetail: null, ...payload,
      };
    default:
      return state;
  }
}
