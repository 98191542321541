import {
  all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import { notification } from '../components';
import { actions } from '../redux/tugas';
import { apiGetTugas, apiGetTugasById } from '../api/api-pokir';

export function* searchTugas() {
  yield takeEvery(actions.GET_TUGAS_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetTugas, payload.data);
      if (response) {
        yield put({
          type: actions.GET_TUGAS_SUCCESS,
          payload: {
            data: response.data
          }
        });
        return;
      }
      yield put({
        type: actions.GET_TUGAS_ERROR,
        payload: {
          message: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.GET_TUGAS_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export function* detailTugas() {
  yield takeEvery(actions.GET_TUGAS_DETAIL_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetTugasById, payload.id);
      if (response) {
        yield put({
          type: actions.GET_TUGAS_DETAIL_SUCCESS,
          payload: {
            detail_data: response.data
          }
        });
        return;
      }
      yield put({
        type: actions.GET_TUGAS_DETAIL_ERROR,
        payload: {
          pokirDetailError: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.GET_TUGAS_DETAIL_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(searchTugas),
    fork(detailTugas)
  ]);
}
