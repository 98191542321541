export const actions = {
  ROLE_REQUEST: 'ROLE_REQUEST',
  ROLE_SUCCESS: 'ROLE_SUCCESS',
  ROLE_ERROR: 'ROLE_ERROR',

  ROLE_DETAIL_REQUEST: 'ROLE_DETAIL_REQUEST',
  ROLE_DETAIL_SUCCESS: 'ROLE_DETAIL_SUCCESS',
  ROLE_DETAIL_ERROR: 'ROLE_DETAIL_ERROR',

  searchRole: (data) => {
    return {
      type: actions.ROLE_REQUEST,
      data,
    };
  },
  roleById: (data) => {
    return {
      type: actions.ROLE_DETAIL_REQUEST,
      data,
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  metaData: {
    count: 0,
    page: 1,
    limit: 10,
  },
  roleDetailLoading: false,
  roleDetail: null,
  roleDetailError: null,
};

export default function roleReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.ROLE_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.ROLE_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.ROLE_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };

    case actions.ROLE_DETAIL_REQUEST:
      return {
        ...state, roleDetailLoading: true, roleDetailError: null, roleDetail: null,
      };
    case actions.ROLE_DETAIL_SUCCESS:
      return {
        ...state, ...payload, roleDetailLoading: false, roleDetailError: null,
      };
    case actions.ROLE_DETAIL_ERROR:
      return {
        ...state, roleDetailLoading: false, roleDetail: null, ...payload,
      };
    default:
      return state;
  }
}
