import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../dashboard')),
  },
  {
    path: 'blankPage',
    component: asyncComponent(() => import('../blankPage')),
  },
  {
    path: 'authCheck',
    component: asyncComponent(() => import('../AuthCheck')),
  },
  {
    path: 'profile',
    component: asyncComponent(() => import('../account/profile/profile')),
  },
  {
    path: 'user',
    component: asyncComponent(() => import('../user/List')),
  },
  {
    path: 'user/create',
    component: asyncComponent(() => import('../user/Create')),
  },
  {
    path: 'user/:userId',
    component: asyncComponent(() => import('../user/Detail')),
  },
  {
    path: 'user/update/:userId',
    component: asyncComponent(() => import('../user/Update')),
  },
  {
    path: 'legislator',
    component: asyncComponent(() => import('../legislator/List')),
  },
  {
    path: 'legislator/create',
    component: asyncComponent(() => import('../legislator/Create')),
  },
  {
    path: 'legislator/:legislatorId',
    component: asyncComponent(() => import('../legislator/Detail')),
  },
  {
    path: 'legislator/update/:legislatorId',
    component: asyncComponent(() => import('../legislator/Update')),
  },
  {
    path: 'ratingLegislator',
    component: asyncComponent(() => import('../ratingLegislator/List')),
  },
  {
    path: 'ratingLegislator/create',
    component: asyncComponent(() => import('../ratingLegislator/Create')),
  },
  {
    path: 'ratingLegislator/:ratingLegislator',
    component: asyncComponent(() => import('../ratingLegislator/Detail')),
  },
  {
    path: 'ratingLegislator/update/:legislatorId',
    component: asyncComponent(() => import('../ratingLegislator/Update')),
  },
  {
    path: 'pokir',
    component: asyncComponent(() => import('../pokir/List')),
  },
  {
    path: 'rapbd',
    component: asyncComponent(() => import('../rapbd/List')),
  },
  {
    path: 'pokir/:pokirId',
    component: asyncComponent(() => import('../pokir/Detail')),
  },
  {
    path: 'rapbd/:rapbdId',
    component: asyncComponent(() => import('../rapbd/Detail')),
  },
  {
    path: 'agenda',
    component: asyncComponent(() => import('../agenda/NewList')),
  },
  {
    path: 'province',
    component: asyncComponent(() => import('../mdm/province/List')),
  },
  {
    path: 'province/create',
    component: asyncComponent(() => import('../mdm/province/Create')),
  },
  {
    path: 'province/:provinceId',
    component: asyncComponent(() => import('../mdm/province/Detail')),
  },
  {
    path: 'banner',
    component: asyncComponent(() => import('../mdm/banner/List')),
  },
  {
    path: 'banner/create',
    component: asyncComponent(() => import('../mdm/banner/Create')),
  },
  {
    path: 'banner/:bannerId',
    component: asyncComponent(() => import('../mdm/banner/Detail')),
  },
  {
    path: 'city',
    component: asyncComponent(() => import('../mdm/city/List')),
  },
  {
    path: 'city/create',
    component: asyncComponent(() => import('../mdm/city/Create')),
  },
  {
    path: 'city/:cityId',
    component: asyncComponent(() => import('../mdm/city/Detail')),
  },
  {
    path: 'district',
    component: asyncComponent(() => import('../mdm/district/List')),
  },
  {
    path: 'district/create',
    component: asyncComponent(() => import('../mdm/district/Create')),
  },
  {
    path: 'district/:districtId',
    component: asyncComponent(() => import('../mdm/district/Detail')),
  },
  {
    path: 'role',
    component: asyncComponent(() => import('../mdm/role/List')),
  },
  {
    path: 'roletype',
    component: asyncComponent(() => import('../mdm/roletype/List')),
  },
  {
    path: 'village',
    component: asyncComponent(() => import('../mdm/village/List')),
  },
  {
    path: 'village/create',
    component: asyncComponent(() => import('../mdm/village/Create')),
  },
  {
    path: 'village/:villageId',
    component: asyncComponent(() => import('../mdm/village/Detail')),
  },
  {
    path: 'aduanMasyarakat',
    component: asyncComponent(() => import('../aduanMasyarakat/List')),
  },
  {
    path: 'aduanMasyarakat/:id',
    component: asyncComponent(() => import('../aduanMasyarakat/Detail')),
  },
  {
    path: 'laporan',
    component: asyncComponent(() => import('../laporan/List')),
  },
  {
    path: 'laporan/:id',
    component: asyncComponent(() => import('../laporan/Detail')),
  },
  {
    path: 'statistik-aduan',
    component: asyncComponent(() => import('../statistik/aduan')),
  },
  {
    path: 'statistik-legislator',
    component: asyncComponent(() => import('../statistik/legislator')),
  },
  {
    path: 'statistik-users',
    component: asyncComponent(() => import('../statistik/users')),
  },
  {
    path: 'penugasan',
    component: asyncComponent(() => import('../penugasan')),
  },
  {
    path: 'penugasan/:id',
    component: asyncComponent(() => import('../penugasan/detail')),
  },
  {
    path: 'penugasan-create',
    component: asyncComponent(() => import('../penugasan/create')),
  },
  {
    path: 'streaming',
    component: asyncComponent(() => import('../streaming')),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        <Switch>
          {routes.map((singleRoute) => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact !== false}
                key={singleRoute.path}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
        </Switch>
      </div>
    );
  }
}

export default AppRouter;
