/* eslint-disable no-async-promise-executor */
import axios from '../plugins/axios';

export function apiGetLegislator(props) {
  return new Promise(async (resolve, reject) => {
    const url = '/legislator_data';
    try {
      const response = await axios({
        method: 'GET',
        url,
        params: props
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetLegislatorById(id) {
  return new Promise(async (resolve, reject) => {
    const url = `/legislator_data/${id}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiCreateLegislator(data) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: '/legislator_data',
      data,
    }).then((res) => {
      if (res.status === 200) resolve(res.data);
      else reject(res);
    }).catch((err) => {
      if (err.errors) reject(err.data);
      else reject(err);
    });
  });
}

export function apiUpdateLegislator(data, id) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'PUT',
      url: `/legislator_data/${id}`,
      data,
    }).then((res) => {
      if (res.status === 200) resolve(res.data);
      else reject(res);
    }).catch((err) => {
      if (err.errors) reject(err.data);
      else reject(err);
    });
  });
}
