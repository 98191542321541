/* eslint-disable no-async-promise-executor */
/* eslint-disable import/prefer-default-export */
import axios from '../plugins/axios';

export function apiGetRoles() {
  return new Promise(async (resolve, reject) => {
    const url = '/role';
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response.data.data);
    } catch (error) {
      reject(error);
    }
  });
}


export function apiGetRoleTypes() {
  return new Promise(async (resolve, reject) => {
    const url = '/role_type';
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response.data.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetProvinces() {
  return new Promise(async (resolve, reject) => {
    const url = '/province';
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response.data.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetBanner(data) {
  return new Promise(async (resolve, reject) => {
    let url = `/banner?page=${data.page}&limit=${data.limit}`;
    if (data.filter && data.filterValue) url += `&${data.filter}=${data.filterValue}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetBannerById(data) {
  return new Promise(async (resolve, reject) => {
    const url = `/banner/${data.data}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiUpdateBanner(data, id) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'PUT',
      url: `/banner/${id}`,
      data,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function apiDeleteBanner(id) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'DELETE',
      url: `/banner/${id}`,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function apiCreateBanner(data) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: '/banner',
      data,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function apiGetListProvince(data) {
  return new Promise(async (resolve, reject) => {
    let url = `/province?page=${data.page}&limit=${data.limit}`;
    if (data.filter && data.filterValue) url += `&${data.filter}=${data.filterValue}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetProvinceById(data) {
  return new Promise(async (resolve, reject) => {
    const url = `/province/${data.data}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiCreateProvince(data) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: '/province',
      data,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function apiUpdateProvince(data, id) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'PUT',
      url: `/province/${id}`,
      data,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function apiDeleteProvince(id) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'DELETE',
      url: `/province/${id}`,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function apiGetCities(data) {
  return new Promise(async (resolve, reject) => {
    let url = '/city?';
    if (data.province_code) url += `province_code=${data.province_code}`;
    if (data.province_id) url += `province_id=${data.province_id}`;
    if (data.id) url += `id=${data.id}`;
    if (data.name) url += `name=${data.name}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response.data.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetListCity(data) {
  return new Promise(async (resolve, reject) => {
    let url = `/city?page=${data.page}&limit=${data.limit}`;
    if (data.filter && data.filterValue) url += `&${data.filter}=${data.filterValue}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetCityById(data) {
  return new Promise(async (resolve, reject) => {
    const url = `/city/${data.data}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiCreateCity(data) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: '/city',
      data,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function apiUpdateCity(data, id) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'PUT',
      url: `/city/${id}`,
      data,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function apiDeleteCity(id) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'DELETE',
      url: `/city/${id}`,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function apiGetDistrict(data) {
  return new Promise(async (resolve, reject) => {
    let url = '/district?';
    if (data.city_id) url += `city_id=${data.city_id}`;
    if (data.city_code) url += `city_code=${data.city_code}`;
    if (data.province_code) url += `&province_code=${data.province_code}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response.data.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetListDistrict(data) {
  return new Promise(async (resolve, reject) => {
    let url = `/district?page=${data.page}&limit=${data.limit}`;
    if (data.filter && data.filterValue) url += `&${data.filter}=${data.filterValue}`;
    if (data.selectedProvince) url += `&province_code=${data.selectedProvince}`;
    if (data.selectedCity) url += `&city_code=${data.selectedCity}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetDistrictById(data) {
  return new Promise(async (resolve, reject) => {
    const url = `/district/${data.data}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiCreateDistrict(data) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: '/district',
      data,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function apiUpdateDistrict(data, id) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'PUT',
      url: `/district/${id}`,
      data,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function apiDeleteDistrict(id) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'DELETE',
      url: `/district/${id}`,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function apiGetVillages(data) {
  return new Promise(async (resolve, reject) => {
    let url = '/village?';
    if (data.district_id) url += `district_id=${data.district_id}`;
    if (data.district_code) url += `district_code=${data.district_code}`;
    if (data.province_code) url += `&province_code=${data.province_code}`;
    if (data.city_code) url += `&city_code=${data.city_code}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response.data.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetListVillage(data) {
  return new Promise(async (resolve, reject) => {
    let url = `/village?page=${data.page}&limit=${data.limit}`;
    if (data.filter && data.filterValue) url += `&${data.filter}=${data.filterValue}`;
    if (data.selectedProvince) url += `&province_code=${data.selectedProvince}`;
    if (data.selectedCity) url += `&city_code=${data.selectedCity}`;
    if (data.selectedDistrict) url += `&district_code=${data.selectedDistrict}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetVillageById(data) {
  return new Promise(async (resolve, reject) => {
    const url = `/village/${data.data}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiCreateVillage(data) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: '/village',
      data,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function apiUpdateVillage(data, id) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'PUT',
      url: `/village/${id}`,
      data,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function apiDeleteVillage(id) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'DELETE',
      url: `/village/${id}`,
    }).then((res) => {
      if (res.status === 200) resolve(res);
      else reject(res.response);
    }).catch((err) => {
      reject(err);
    });
  });
}
