export const actions = {
  CITY_REQUEST: 'CITY_REQUEST',
  CITY_SUCCESS: 'CITY_SUCCESS',
  CITY_ERROR: 'CITY_ERROR',

  CITY_DETAIL_REQUEST: 'CITY_DETAIL_REQUEST',
  CITY_DETAIL_SUCCESS: 'CITY_DETAIL_SUCCESS',
  CITY_DETAIL_ERROR: 'CITY_DETAIL_ERROR',

  searchCity: (data) => {
    return {
      type: actions.CITY_REQUEST,
      data,
    };
  },
  cityById: (data) => {
    return {
      type: actions.CITY_DETAIL_REQUEST,
      data,
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  metaData: {
    count: 0,
    page: 1,
    limit: 10,
  },
  cityDetailLoading: false,
  cityDetail: null,
  cityDetailError: null,
};

export default function cityReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.CITY_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.CITY_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.CITY_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };

    case actions.CITY_DETAIL_REQUEST:
      return {
        ...state, cityDetailLoading: true, cityDetailError: null, cityDetail: null,
      };
    case actions.CITY_DETAIL_SUCCESS:
      return {
        ...state, ...payload, cityDetailLoading: false, cityDetailError: null,
      };
    case actions.CITY_DETAIL_ERROR:
      return {
        ...state, cityDetailLoading: false, cityDetail: null, ...payload,
      };
    default:
      return state;
  }
}
