/* eslint-disable no-use-before-define */
import moment from 'moment';

export const toMomentDateStart = (input, format) => {
  return moment(input, format).startOf('day');
};
export const toMomentDateEnd = (input, format) => {
  return moment(input, format).endOf('day');
};
export const toIsoStringDateStart = (input, format) => {
  return moment(input, format).startOf('day').toISOString();
};
export const toIsoStringDateEnd = (input, format) => {
  return moment(input, format).endOf('day').toISOString();
};

export const campaignTypeName = {
  HD: 'Hot Deals',
  MI: 'Misc',
  PM: 'Promotion',
};

export const delay = (function () {
  let timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
}());

// Form Helpers
export function validate(validationSchema) {
  return (values) => {
    const newValidationSchema = validationSchema(values);
    try {
      newValidationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
}
export function getErrorsFromValidationError(validationError) {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
}

// Localstorage Helpers
export function parseStringObject(data) {
  try {
    return JSON.parse(data);
  } catch (error) {
    return {};
  }
}

export function clearLocalStorage(props = { key: '' }) {
  localStorage.removeItem(props.key);
}

export function setLocalStorage(props = {}) {
  const defaultProps = {
    key: '',
    value: {},
  };
  const curProps = { ...defaultProps, ...props };
  localStorage.setItem(curProps.key, JSON.stringify(curProps.value));
}

export function getLocalStorage(props = { key: '' }) {
  if (!props.key) {
    return {};
  }
  const storageString = localStorage.getItem(props.key);
  return parseStringObject(storageString);
}

export function clearTokenInLocalStorage() {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  // localStorage.removeItem('token');
  // localStorage.removeItem('refresh_token');
}

export function setTokenInLocalStorage(props) {
  localStorage.setItem('token', props.token);
  localStorage.setItem('refresh_token', props.refreshToken);
  // localStorage.setItem('token', JSON.stringify(props));
  // localStorage.setItem('refresh_token', JSON.stringify({ refresh_token: props.refresh_token }));
}

export function getTokenInLocalStorage() {
  let result = {
    token: '',
    refresh_token: '',
  };
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refresh_token');
  if (!token || !refreshToken) {
    return result;
  }
  result = {
    token,
    refresh_token: refreshToken,
  };
  return result;
}

export function getRequestHeaders(props = { withToken: false }) {
  const requestHeaders = {
    'Content-Type': 'application/json',
  };
  if (props.withToken) {
    return requestHeaders;
  }
  const cwsAuth = getTokenInLocalStorage();
  requestHeaders.Authorization = cwsAuth.token;
  return requestHeaders;
}

// Route Helpers
export function getRouteProps(props) {
  return {
    match: props.math,
    location: props.location,
    history: props.history,
    staticContext: props.staticContext,
  };
}

export function removeNonObjectInArray(props = { arr: [], value: '' }) {
  const index = props.arr.indexOf(props.value);
  if (index > -1) {
    props.arr.splice(index, 1);
    return props.arr;
  }
  return props.arr;
}

export function buildArrayBasedOnNumber(count) {
  const arr = [];
  for (let index = 0; index < count; index += 1) {
    arr.push(index);
  }
  return arr;
}

export function priceFormatter(price) {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 2,
  });

  return formatter.format(price);
}

export function volumeFormatter(val, type) {
  let convert = null;

  switch (type) {
    case 'm3': {
      const calc = val;
      convert = calc;
      break;
    }
    case 'l': {
      const calc = val / 1000;
      convert = calc;
      break;
    }
    case 'ml': {
      const calc = val / 1000000;
      convert = calc;
      break;
    }
    case 'ccm': {
      const calc = val / 1000000;
      convert = calc;
      break;
    }
    case 'cm3': {
      const calc = val / 1000000;
      convert = calc;
      break;
    }

    default:
      break;
  }
  return convert;
}

export function weightFormatter(val, type) {
  let convert = null;

  switch (type) {
    case 'kg': {
      const calc = val;
      convert = calc;
      break;
    }
    case 'g': {
      const calc = val / 1000;
      convert = calc;
      break;
    }
    case 'gr': {
      const calc = val / 1000000;
      convert = calc;
      break;
    }

    default:
      break;
  }
  return convert;
}
