import auth from './auth';
import app from './app';
import user from './user';
import pokir from './pokir';
import rapbd from './rapbd';
import jadwalAgenda from './jadwalAgenda';
import account from './account/forgotPass';
import province from './province';
import city from './city';
import district from './district';
import role from './role';
import roleType from './roletype';
import village from './village';
import legislator from './legislator';
import banner from './banner';
import aduan from './aduan';
import laporan from './laporan';
import ratingInternal from './ratingInternal';
import StatistikUsers from './statistikUsers';
import StatistikAduan from './statistikAduan';
import tugas from './tugas';

export default {
  auth,
  app,
  account,
  user,
  pokir,
  jadwalAgenda,
  rapbd,
  province,
  city,
  district,
  role,
  roleType,
  village,
  legislator,
  banner,
  aduan,
  laporan,
  ratingInternal,
  StatistikUsers,
  StatistikAduan,
  tugas
};
