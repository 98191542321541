import {
  all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import { notification } from '../components';
import { actions } from '../redux/rapbd';
import { apiGetRapbd, apiGetRapbdById } from '../api/api-rapbd';
import { apiGetCounter } from '../api/api-counter';

export function* searchRapbd() {
  yield takeEvery(actions.RAPBD_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetRapbd, payload.data);
      if (response.status === 200) {
        yield put({
          type: actions.RAPBD_SUCCESS,
          payload: {
            list: response.data.data.rows,
            count: response.data.data.count,
          }
        });
        return;
      }
      yield put({
        type: actions.RAPBD_ERROR,
        payload: {
          message: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.RAPBD_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export function* detailRapbd() {
  yield takeEvery(actions.RAPBD_DETAIL, function* (payload) {
    try {
      const response = yield call(apiGetRapbdById, payload.id);
      if (response.status === 200) {
        yield put({
          type: actions.RAPBD_DETAIL_SUCCESS,
          payload: {
            rapbdDetail: response.data.data
          }
        });
        return;
      }
      yield put({
        type: actions.RAPBD_DETAIL_ERROR,
        payload: {
          rapbdDetailError: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.RAPBD_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export function* counterRapbd() {
  yield takeEvery(actions.COUNTER_RAPBD_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetCounter, payload.data);
      if (response.status === 200) {
        yield put({
          type: actions.COUNTER_RAPBD_SUCCESS,
          payload: {
            counterRapbdList: response.data.rapbd,
          }
        });
        return;
      }
      yield put({
        type: actions.COUNTER_RAPBD_ERROR,
        payload: {
          message: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.COUNTER_RAPBD_ERROR,
        payload: {
          counterRapbdError: errorResponse,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(searchRapbd),
    fork(detailRapbd),
    fork(counterRapbd)
  ]);
}
