export const actions = {
  RAPBD_REQUEST: 'RAPBD_REQUEST',
  RAPBD_SUCCESS: 'RAPBD_SUCCESS',
  RAPBD_ERROR: 'RAPBD_ERROR',
  RAPBD_DETAIL: 'RAPBD_DETAIL',
  RAPBD_DETAIL_SUCCESS: 'RAPBD_DETAIL_SUCCESS',
  RAPBD_DETAIL_ERROR: 'RAPBD_DETAIL_ERROR',
  COUNTER_RAPBD_REQUEST: 'COUNTER_RAPBD_REQUEST',
  COUNTER_RAPBD_SUCCESS: 'COUNTER_RAPBD_SUCCESS',
  COUNTER_RAPBD_ERROR: 'COUNTER_RAPBD_ERROR',

  searchRapbd: (data) => {
    return {
      type: actions.RAPBD_REQUEST,
      data,
    };
  },
  counterRapbd: (data) => {
    return {
      type: actions.COUNTER_RAPBD_REQUEST,
      data,
    };
  },
  rapbdById: (data) => {
    return {
      type: actions.RAPBD_DETAIL,
      id: data,
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  rapbdDetailLoading: false,
  rapbdDetail: null,
  rapbdDetailError: null,
  isCounterRapbdLoading: false,
  counterRapbdList: [],
  counterRapbdError: null,
};

export default function rapbdReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.RAPBD_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.RAPBD_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.RAPBD_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };
    case actions.RAPBD_DETAIL:
      return {
        ...state, rapbdDetailLoading: true, rapbdDetailError: null, rapbdDetail: null,
      };
    case actions.RAPBD_DETAIL_SUCCESS:
      return {
        ...state, ...payload, rapbdDetailLoading: false, rapbdDetailError: null,
      };
    case actions.RAPBD_DETAIL_ERROR:
      return {
        ...state, rapbdDetailLoading: false, rapbdDetail: null, ...payload,
      };
    case actions.COUNTER_RAPBD_REQUEST:
      return {
        ...state, isCounterRapbdLoading: true, counterRapbdError: null,
      };
    case actions.COUNTER_RAPBD_SUCCESS:
      return {
        ...state, ...payload, isCounterRapbdLoading: false, counterRapbdError: null,
      };
    case actions.COUNTER_RAPBD_ERROR:
      return {
        ...state, isCounterRapbdLoading: false, counterRapbdList: [], ...payload,
      };
    default:
      return state;
  }
}
