import { all } from 'redux-saga/effects';
import authSagas from './authSagas';
import userSagas from './userSagas';
import pokirSagas from './pokirSagas';
import rapbdSagas from './rapbdSagas';
import jadwalAgendaSagas from './jadwalAgendaSagas';
import forgotPassSagas from './account/forgotPassSagas';
import provinceSagas from './provinceSagas';
import citySagas from './citySagas';
import districtSagas from './districtSagas';
import roleSagas from './roleSagas';
import roleTypeSagas from './roleTypeSagas';
import villageSagas from './villageSagas';
import legislatorSagas from './legislatorSagas';
import bannerSagas from './bannerSagas';
import aduanSagas from './aduanSagas';
import laporanSagas from './laporanSagas';
import ratingInternalSagas from './ratingInternalSagas';
import statistikSagas from './statistikSagas';
import tugasSagas from './tugasSagas';

export default function* rootSaga() {
  yield all([
    authSagas(),
    userSagas(),
    forgotPassSagas(),
    pokirSagas(),
    rapbdSagas(),
    jadwalAgendaSagas(),
    provinceSagas(),
    citySagas(),
    districtSagas(),
    roleSagas(),
    roleTypeSagas(),
    villageSagas(),
    legislatorSagas(),
    bannerSagas(),
    aduanSagas(),
    laporanSagas(),
    ratingInternalSagas(),
    statistikSagas(),
    tugasSagas(),
  ]);
}
