import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import PublicRoutes from './router';
import { store, history } from './redux/store';
import themes from './settings/themes';
import AppLocale from './languageProvider';
import { themeConfig } from './settings';
import DashAppHolder from './dashAppStyle';
import Boot from './redux/boot';

const currentAppLocale = AppLocale.en;

class DashApp extends Component {
  render() {
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes[themeConfig.theme]}>
            <DashAppHolder>
              <Provider store={store}>
                <PublicRoutes history={history} />
              </Provider>
            </DashAppHolder>
          </ThemeProvider>
        </IntlProvider>
      </ConfigProvider>
    );
  }
}
Boot()
  .then(() => DashApp())
  // eslint-disable-next-line no-console
  .catch((error) => console.log(error));

export default DashApp;
export { AppLocale };
