export const actions = {
  STATISTIK_USER_REQUEST: 'STATISTIK_USER_REQUEST',
  STATISTIK_USER_SUCCESS: 'STATISTIK_USER_SUCCESS',
  STATISTIK_USER_ERROR: 'STATISTIK_USER_ERROR',

  STATISTIK_LEGISLATOR_REQUEST: 'STATISTIK_LEGISLATOR_REQUEST',
  STATISTIK_LEGISLATOR_SUCCESS: 'STATISTIK_LEGISLATOR_SUCCESS',
  STATISTIK_LEGISLATOR_ERROR: 'STATISTIK_LEGISLATOR_ERROR',

  TABLE_LEGISLATOR_REQUEST: 'TABLE_LEGISLATOR_REQUEST',
  TABLE_LEGISLATOR_SUCCESS: 'TABLE_LEGISLATOR_SUCCESS',
  TABLE_LEGISLATOR_ERROR: 'TABLE_LEGISLATOR_ERROR',

  getStatistikUsers: () => {
    return {
      type: actions.STATISTIK_USER_REQUEST
    };
  },

  getStatistikLegislator: () => {
    return {
      type: actions.STATISTIK_LEGISLATOR_REQUEST
    };
  },

  getDetailLegislator: (data) => {
    return {
      type: actions.TABLE_LEGISLATOR_REQUEST,
      data,
    };
  },
};

const initState = {
  isLoading: false,
  active_users: 0,
  usia: null,
  demografi: null,
  error_message: null,
  statistik_legislator_loading: false,
  legislator_pokir: null,
  legislator_laporan: null,
  legislator_rapbd: null,
  legislator_error: null,
  table_legislator_loading: false,
  table_legislator: [],
  table_legislator_error: null,
};

export default function StatistikUsers(state = initState, { type, payload }) {
  switch (type) {
    case actions.STATISTIK_USER_REQUEST:
      return {
        ...state, isLoading: true, error_message: null,
      };
    case actions.STATISTIK_USER_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error_message: null,
      };
    case actions.STATISTIK_USER_ERROR:
      return {
        ...state, isLoading: false, active_users: 0, usia: null, demografi: null, ...payload,
      };

    case actions.STATISTIK_LEGISLATOR_REQUEST:
      return {
        ...state,
        statistik_legislator_loading: true,
        legislator_error: null,
      };
    case actions.STATISTIK_LEGISLATOR_SUCCESS:
      return {
        ...state,
        ...payload,
        statistik_legislator_loading: false,
        legislator_error: null,
      };
    case actions.STATISTIK_LEGISLATOR_ERROR:
      return {
        ...state,
        statistik_legislator_loading: false,
        legislator_pokir: null,
        legislator_laporan: null,
        legislator_rapbd: null,
        legislator_error: null,
        ...payload,
      };

    case actions.TABLE_LEGISLATOR_REQUEST:
      return {
        ...state,
        table_legislator_loading: true,
        table_legislator_error: null,
      };
    case actions.TABLE_LEGISLATOR_SUCCESS:
      return {
        ...state,
        ...payload,
        table_legislator_loading: false,
        table_legislator_error: null,
      };
    case actions.TABLE_LEGISLATOR_ERROR:
      return {
        ...state,
        table_legislator_loading: false,
        table_legislator: [],
        table_legislator_error: null,
        ...payload,
      };
    default:
      return state;
  }
}
