/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import clone from 'clone';
import { Link } from 'react-router-dom';
import { Layout, Icon } from 'antd';
import options from './options';
import Scrollbars from '../../components/utility/customScrollBar';
import Menu from '../../components/uielements/menu';
import SidebarWrapper from './sidebar.style';
import { actions as appActions } from '../../redux/app';
import Logo from '../../components/utility/logo';
import themes from '../../settings/themes';
import { themeConfig } from '../../settings';
import { URL_ROOT } from '../../page-url';

const { SubMenu } = Menu;
const { Sider } = Layout;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;
const stripTrailingSlash = (str) => {
  if (str.substr(-1) === URL_ROOT()) {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps && nextProps.userPermission && nextProps.userPermission.app && nextProps.userPermission.app.menus !== prevState.menu) {
      const perm = nextProps.userPermission;
      return {
        menu: [
          {
            id: 270,
            name: 'User',
            fe_code: 'URL_CALL_PLAN',
            fe_icon: 'icon-size-fullscreen',
            fe_url: '/user',
            type: 'MENU',
            api_method: null,
            api_route: null,
            sub: [
              {
                id: 271,
                name: 'List User',
                fe_code: 'URL_CALL_PLAN_SET_CALL_PLAN',
                fe_icon: null,
                fe_url: '/user/list',
                type: 'ACTION',
                api_method: null,
                api_route: null
              },
            ]
          },
        ]
      };
    }
    return null;
  }

  onOpenChange(newOpenKeys) {
    // eslint-disable-next-line no-shadow
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      (key) => !(app.openKeys.indexOf(key) > -1),
    );
    const latestCloseKey = app.openKeys.find(
      (key) => !(newOpenKeys.indexOf(key) > -1),
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }

  getAncestorKeys = (key) => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };

  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { props } = this;
    const {
      key, label, leftIcon, children,
    } = singleOption;
    const url = stripTrailingSlash(props.url);
    if (children) {
      return (
        <SubMenu
          key={key}
          title={(
            <span className="isoMenuHolder" style={submenuColor}>
              <Icon type={leftIcon} />
              <span className="nav-text">
                {label}
              </span>
            </span>
          )}
        >
          {children.map((child) => {
            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`;
            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link style={submenuColor} to={linkTo}>
                  { child.label }
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span className="isoMenuHolder" style={submenuColor}>
            <Icon type={leftIcon} />
            <span className="nav-text">
              { label }
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };

  handleClick(e) {
    const { props } = this;
    props.changeCurrent([e.key]);
    if (props.app.view === 'MobileViewzzz') {
      setTimeout(() => {
        props.toggleCollapsed();
        props.toggleOpenDrawer();
      }, 100);
    }
  }

  render() {
    // eslint-disable-next-line no-shadow
    const { app, toggleOpenDrawer, height } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? 'vertical' : 'inline';
    const customizedTheme = themes[themeConfig.theme];
    const styling = {
      backgroundColor: '#e62129',
    };
    const submenuStyle = {
      color: customizedTheme.textColor,
    };
    const submenuColor = {
      color: customizedTheme.textColor,
    };
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={240}
          className="isomorphicSidebar"
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: height - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {options.map((singleOption) => this.getMenuItem({ submenuStyle, submenuColor, singleOption }))}
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    app: state.app,
    height: state.app.height,
    userPermission: state.auth.user,
  }),
  {
    toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed,
  },
)(Sidebar);
