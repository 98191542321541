import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { createBrowserHistory } from 'history';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import rootSaga from '../sagas';

const history = createBrowserHistory({ forceRefresh: false });
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];
const enhancerList = [];

enhancerList.push(applyMiddleware(...middlewares));

if (process.env.REACT_APP_REDUX_DEVTOOLS === 'true' && window.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancerList.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const store = createStore(
  combineReducers({
    ...reducers,
    router: routerReducer,
    loadingBar: loadingBarReducer,
  }),
  compose(...enhancerList),
);
sagaMiddleware.run(rootSaga);
export { store, history };
