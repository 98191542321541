/* eslint-disable import/prefer-default-export */
import axios from '../plugins/axios';

export function uploadFile(file, path) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    axios.post(`util/upload?path=${path}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      if (res.status === 200) resolve(res.data);
      else reject(res);
    }).catch((err) => {
      if (err.errors) reject(err.data);
      else reject(err);
    });
  });
}


export function getDemografi() {
  return new Promise((resolve, reject) => {
    const url = '/demografi-users';
    axios({
      method: 'GET',
      url,
    }).then(({ status, data, response }) => {
      if (status === 200) resolve(data.data);
      else reject(response);
    }).catch((err) => reject(err));
  });
}


export function getStatistikLegislator() {
  return new Promise((resolve, reject) => {
    const url = '/demografi-legislators';
    axios({
      method: 'GET',
      url,
    }).then(({ status, data, response }) => {
      if (status === 200) resolve(data.data);
      else reject(response);
    }).catch((err) => reject(err));
  });
}

export function getTableLegislator(params) {
  return new Promise((resolve, reject) => {
    const url = '/statistik-legislators';
    axios({
      method: 'GET',
      url,
      params,
    }).then(({ status, data, response }) => {
      if (status === 200) resolve(data.data);
      else reject(response);
    }).catch((err) => reject(err));
  });
}

export function getDemografiAduan() {
  return new Promise((resolve, reject) => {
    const url = '/statistik-aduan';
    axios({
      method: 'GET',
      url,
    }).then(({ status, data, response }) => {
      if (status === 200) resolve(data.data);
      else reject(response);
    }).catch((err) => reject(err));
  });
}
