/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Route, Redirect, Router } from 'react-router-dom';
// import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';
import {
  URL_ROOT, URL_DASHBOARD, URL_LOGIN, URL_VERIFY, URL_FORGOT_PASSWORD, URL_REGISTRATION, URL_NO_PERMISSION
} from './page-url';

import App from './containers/App/App';
import SampleApp from './containers/sample/App/App';
import asyncComponent from './helpers/AsyncFunc';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (isLoggedIn ? (
      <Component {...props} />
    ) : (<Redirect to={{ pathname: URL_LOGIN(), state: { from: props.location } }} />))}
  />
);
const SampleRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) => <Component {...props} />}
  />
);

const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <Router history={history}>
      <div>
        <Route
          exact
          path={URL_ROOT()}
          component={asyncComponent(() => import('./containers/signin/signin'))}
        />
        <Route
          exact
          path={URL_NO_PERMISSION()}
          component={asyncComponent(() => import('./containers/Page/noAccess'))}
        />
        <Route
          exact
          path={URL_LOGIN()}
          component={asyncComponent(() => import('./containers/signin/signin'))}
        />
        <Route
          exact
          path={URL_VERIFY()}
          component={asyncComponent(() => import('./containers/verify/index'))}
        />
        <Route
          exact
          path="/update-password/:token"
          component={asyncComponent(() => import('./containers/updatePassword'))}
        />
        <Route
          exact
          path={URL_FORGOT_PASSWORD()}
          component={asyncComponent(() => import('./containers/account/forgotPass/forgotPass'))}
        />
        <Route
          exact
          path={URL_REGISTRATION()}
          component={asyncComponent(() => import('./containers/account/registration/registrationPage'))}
        />
        <RestrictedRoute
          path={URL_DASHBOARD()}
          component={App}
          isLoggedIn={isLoggedIn}
        />
        <SampleRoute
          path="/sample"
          component={SampleApp}
        />
      </div>
    </Router>
  );
};

export default connect((state) => ({
  isLoggedIn: state.auth.token !== null,
}))(PublicRoutes);
