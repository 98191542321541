export const actions = {
  ROLETYPE_REQUEST: 'ROLETYPE_REQUEST',
  ROLETYPE_SUCCESS: 'ROLETYPE_SUCCESS',
  ROLETYPE_ERROR: 'ROLETYPE_ERROR',

  ROLETYPE_DETAIL_REQUEST: 'ROLETYPE_DETAIL_REQUEST',
  ROLETYPE_DETAIL_SUCCESS: 'ROLETYPE_DETAIL_SUCCESS',
  ROLETYPE_DETAIL_ERROR: 'ROLETYPE_DETAIL_ERROR',

  searchRoleType: (data) => {
    return {
      type: actions.ROLETYPE_REQUEST,
      data,
    };
  },
  roleTypeById: (data) => {
    return {
      type: actions.ROLETYPE_DETAIL_REQUEST,
      data,
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  metaData: {
    count: 0,
    page: 1,
    limit: 10,
  },
  roleTypeDetailLoading: false,
  roleTypeDetail: null,
  roleTypeDetailError: null,
};

export default function roleTypeReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.ROLETYPE_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.ROLETYPE_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.ROLETYPE_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };

    case actions.ROLETYPE_DETAIL_REQUEST:
      return {
        ...state, roleTypeDetailLoading: true, roleTypeDetailError: null, roleTypeDetail: null,
      };
    case actions.ROLETYPE_DETAIL_SUCCESS:
      return {
        ...state, ...payload, roleTypeDetailLoading: false, roleTypeDetailError: null,
      };
    case actions.ROLETYPE_DETAIL_ERROR:
      return {
        ...state, roleTypeDetailLoading: false, roleTypeDetail: null, ...payload,
      };
    default:
      return state;
  }
}
