import axios from 'axios';
import { apiPostRefreshToken } from '../api/api-auth';
import {
  clearTokenInLocalStorage,
  clearLocalStorage,
} from '../utils/helpers';

function logout() {
  clearTokenInLocalStorage();
  clearLocalStorage({ key: 'cws-usr' });
  localStorage.removeItem('is-refreshed');
  window.location.replace('/');
}


axios.interceptors.request.use(
  (config) => {
    const newConfig = {
      ...config,
      ...{
        baseURL: `${process.env.REACT_APP_AUTH_URL}`,
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
          'x-api-key': process.env.REACT_APP_IR_KEY,
          Authorization: localStorage.getItem('token'),
        },
      },
    };
    return newConfig;
  },
  (err) => Promise.reject(err),
);

axios.interceptors.response.use((res) => {
  return res;
}, (err) => {
  const { config } = err;
  if (err.response && err.response.data && err.response.data.message === 'Signature verification failed') {
    logout();
    return Promise.reject(err);
  }
  if (err.response.status === 401) {
    return apiPostRefreshToken().then((response) => {
      localStorage.setItem('token', response.data.token);
      const newConfig = {
        ...config,
        ...{
          headers: {
            'Content-type': 'application/json',
            authorization: localStorage.getItem('token'),
          },
        },
      };
      return axios(newConfig);
    });
  }
  return err;
});

export default axios;
