/* eslint-disable no-async-promise-executor */
import jwtDecode from 'jwt-decode';
import axios from '../plugins/axios';

import {
  clearLocalStorage,
  setLocalStorage,
  getRequestHeaders,
  clearTokenInLocalStorage,
} from '../utils/helpers';

export function apiGetUserMe() {
  return new Promise(async (resolve, reject) => {
    try {
      // const response = await axios({
      //   method: 'GET',
      //   url: `/auth/profile`,
      // });
      const app_permission = await axios({
        method: 'GET',
        url: '/auth/check?apps_id=TMS',
      });
      if (app_permission.status === 200) {
        setLocalStorage({
          key: 'cws-usr',
          value: JSON.stringify(app_permission.data.data),
        });
        setLocalStorage({
          key: 'tms-allow',
          value: true,
        });
      } else {
        clearTokenInLocalStorage();
        clearLocalStorage({ key: 'cws-usr' });
        clearLocalStorage({ key: 'tms-allow' });
      }
      resolve(app_permission);
    } catch (error) {
      clearTokenInLocalStorage();
      clearLocalStorage({ key: 'cws-usr' });
      clearLocalStorage({ key: 'tms-allow' });
      reject(error);
    }
  });
}

export function apiGetProfile() {
  return new Promise((resolve, reject) => {
    const user = jwtDecode(localStorage.getItem('token'));
    axios({
      method: 'GET',
      url: `/users/${user.id}`,
    }).then((res) => {
      if (res.status === 200) resolve(res.data);
    }).catch((err) => reject(err));
  });
}

export function apiGetUser(props) {
  return new Promise(async (resolve, reject) => {
    let url = '/users?';
    if (props && props.limit) {
      url += `&limit=${props.limit}`;
    }
    if (props && props.page) url += `&page=${props.page}`;
    if (props && props.name) url += `&name=${props.name}`;
    if (props && props.email) url += `&email=${props.email}`;
    if (props.roleId) url += `&roles_id=${props.roleId}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetDetailUser(id) {
  return new Promise(async (resolve, reject) => {
    const url = `/users/${id}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetForgotPassword(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'POST',
        url: '/auth/forgot-password',
        headers: getRequestHeaders({ withToken: false }),
        data: {
          username: payload.username,
        },
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiNewPassword(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'POST',
        url: '/auth/reset-password',
        headers: getRequestHeaders({ withToken: false }),
        data,
      });
      if (response.status !== 200) {
        reject(response.response.data);
      }
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiRegister(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'POST',
        url: '/auth/register',
        headers: getRequestHeaders({ withToken: false }),
        data: payload,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function createUser(data) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: '/users',
      data,
    }).then((res) => {
      if (res.status === 200) resolve(res.data);
      else reject(res);
    }).catch((err) => {
      if (err.errors) reject(err.data);
      else reject(err);
    });
  });
}

export function updateUser(data, id) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'PUT',
      url: `/users/${id}`,
      data,
    }).then((res) => {
      if (res.status === 200) resolve(res.data);
      else reject(res);
    }).catch((err) => {
      if (err.errors) reject(err.data);
      else reject(err);
    });
  });
}

export function deleteUser(id) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'DELETE',
      url: `/users/${id}`,
    }).then((res) => {
      if (res.status === 200) resolve(res.data);
      else reject(res);
    }).catch((err) => {
      if (err.errors) reject(err.data);
      else reject(err);
    });
  });
}

export function updatePasswordUser(id, password) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: `/users/update-password/${id}`,
      data: {
        password
      }
    }).then((res) => {
      if (res.status === 200) resolve(res.data);
      else reject(res);
    }).catch((err) => {
      if (err.response) reject(err.response.data);
      else reject(err);
    });
  });
}
